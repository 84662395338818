import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBAnimation } from 'mdbreact'
import Layout from '../../components/layout'
import { Link } from 'gatsby'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'

export default ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
      <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fluid.src}          
        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.fluid}
          foreimage={null}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}          
          type="root"
        />
      </div>

      {post.frontmatter.section.map((type, index) => {
        return (
          <section className={index % 2 === 0 ? 'bg-white' : 'bg-gray-light'} key={index}>
            <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
              <MDBContainer>
                <MDBRow>
                  <MDBCol>
                    <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                      {type.title}
                    </h2>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mt-5">
                  {type.feature.map((partner, subindex) => {
                    return (
                      <MDBCol md={index % 2 === 0 ? '4' : '3'} className="pb-3" key={subindex}>
                        <MDBCard className="outline card-hover">
                          <Link
                            to={
                              partner.link.substring(0, 1) == '/'
                                ? partner.link
                                : '/' + partner.link + '/'
                            }
                          >
                            <Img
                              fluid={partner.image.childImageSharp.fluid}
                              alt={partner.alttext}
                              className="rounded"
                            />
                          </Link>
                        </MDBCard>
                      </MDBCol>
                    )
                  })}
                </MDBRow>
              </MDBContainer>
            </MDBAnimation>
          </section>
        )
      })}
    </Layout>
  )
}
export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(frontmatter: { name: { eq: "technology-partners" } }) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alttext
        ctatext
        ctaslug        
        section {
          title
          feature {
            image {
              childImageSharp {
                fluid(maxWidth: 540, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alttext
            link
          }
        }
      }
      html
    }
  }
`
